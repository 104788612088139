import { createContext, useContext, useState, useEffect } from 'react';
import { User, AuthContextType, AuthProviderProps } from '@/lib/types';
import { users, updateUsers, generateVerificationToken, verifyEmailToken } from '@/lib/auth';
import { storage } from '@/lib/storage';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(() => storage.loadCurrentUser());

  // Load saved users on mount
  useEffect(() => {
    const savedUsers = storage.loadUsers(users);
    updateUsers(savedUsers);
  }, []);

  const login = async (email: string, password: string) => {
    const user = users.find(u => u.email === email && u.password === password);
    
    if (!user) {
      throw new Error('Invalid credentials');
    }

    if (!user.emailVerified) {
      throw new Error('Please verify your email before logging in');
    }

    setUser(user);
    storage.saveCurrentUser(user);
  };

  const logout = () => {
    setUser(null);
    storage.saveCurrentUser(null);
  };

  const sendVerificationEmail = async (email: string) => {
    const token = generateVerificationToken(email);
    
    // In a real app, you would send an email here
    // For demo purposes, we'll just log it
    console.log(`Verification link: /verify-email?token=${token}`);
    
    // Simulate email sending delay
    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const verifyEmail = async (token: string) => {
    const email = verifyEmailToken(token);
    if (!email) {
      throw new Error('Invalid or expired verification token');
    }

    // Update user's verified status
    const updatedUsers = users.map(user =>
      user.email === email ? { ...user, emailVerified: true } : user
    );
    updateUsers(updatedUsers);
    storage.saveUsers(updatedUsers);
  };

  return (
    <AuthContext.Provider value={{
      user,
      login,
      logout,
      isAuthenticated: !!user,
      sendVerificationEmail,
      verifyEmail
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}