import { ViewType } from '@/lib/types';
import { StatsCards } from '@/components/dashboard/StatsCards';
import { Button } from '@/components/ui/button';
import UserManagement from '@/components/UserManagement';
import { useAuth } from '@/contexts/AuthContext';
import { users, updateUsers, hasPermission } from '@/lib/auth';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { BoxIcon, BarChart3Icon, ClipboardCheckIcon } from 'lucide-react';

interface DashboardProps {
  totalValue: string;
  totalStreams: number;
  onViewChange: (view: ViewType) => void;
  onStartInventoryCheck: () => void;
}

export default function Dashboard({
  totalValue,
  totalStreams,
  onViewChange,
  onStartInventoryCheck,
}: DashboardProps) {
  const { user } = useAuth();
  const canManageUsers = hasPermission(user, 'manage_users');

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-4xl font-bold tracking-tight mb-2">Welcome back</h1>
        <p className="text-muted-foreground">Here's an overview of your inventory management system.</p>
      </div>

      <StatsCards totalValue={totalValue} totalStreams={totalStreams} />

      <div className="grid gap-6 md:grid-cols-3">
        <Card className="hover:border-primary/50 transition-colors cursor-pointer" onClick={() => onViewChange('inventory')}>
          <CardHeader>
            <BoxIcon className="h-6 w-6 mb-2 text-primary" />
            <CardTitle className="text-xl font-semibold">Inventory</CardTitle>
            <CardDescription>Manage your product catalog</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground">
              View, add, edit, and organize your inventory items.
            </p>
          </CardContent>
        </Card>

        <Card className="hover:border-primary/50 transition-colors cursor-pointer" onClick={() => onViewChange('streams')}>
          <CardHeader>
            <BarChart3Icon className="h-6 w-6 mb-2 text-primary" />
            <CardTitle className="text-xl font-semibold">Streams</CardTitle>
            <CardDescription>Track your streaming sessions</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground">
              Monitor and manage your streaming activities.
            </p>
          </CardContent>
        </Card>

        <Card className="hover:border-primary/50 transition-colors cursor-pointer" onClick={onStartInventoryCheck}>
          <CardHeader>
            <ClipboardCheckIcon className="h-6 w-6 mb-2 text-primary" />
            <CardTitle className="text-xl font-semibold">Inventory Check</CardTitle>
            <CardDescription>Verify your stock levels</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground">
              Perform inventory checks and reconciliation.
            </p>
          </CardContent>
        </Card>
      </div>

      {canManageUsers && (
        <div className="mt-8">
          <Card className="glass-card">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">User Management</CardTitle>
              <CardDescription>Manage system access and permissions</CardDescription>
            </CardHeader>
            <CardContent>
              <UserManagement
                users={users}
                onUpdateUsers={updateUsers}
              />
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
}