import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { DollarSign, TrendingUp } from 'lucide-react';

interface StatsCardsProps {
  totalValue: string;
  totalStreams: number;
}

export function StatsCards({ totalValue, totalStreams }: StatsCardsProps) {
  // Calculate total sales from the last 30 days
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <Card className="stats-card overflow-hidden">
        <CardHeader className="stats-header">
          <CardTitle className="text-lg font-medium text-white">Total Inventory Value</CardTitle>
          <DollarSign className="h-6 w-6 text-white/90" />
        </CardHeader>
        <CardContent className="stats-content">
          <div className="text-4xl font-light tracking-tight">{totalValue}</div>
          <p className="text-sm text-muted-foreground">Current portfolio value</p>
        </CardContent>
      </Card>

      <Card className="stats-card overflow-hidden">
        <CardHeader className="stats-header">
          <CardTitle className="text-lg font-medium text-white">Recent Sales</CardTitle>
          <TrendingUp className="h-6 w-6 text-white/90" />
        </CardHeader>
        <CardContent className="stats-content">
          <div className="text-4xl font-light tracking-tight">
            {totalStreams} streams
          </div>
          <p className="text-sm text-muted-foreground">Total streams completed</p>
        </CardContent>
      </Card>
    </div>
  );
}