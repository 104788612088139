import { User, UserRole, Permission } from './types';
import { generateId } from './utils';
import { storage } from './storage';

export const defaultPermissions: Record<UserRole, Permission[]> = {
  admin: [
    'view',
    'edit',
    'delete',
    'manage_users',
    'view_costs',
    'view_all_streams',
    'view_own_streams',
    'add_stream',
    'edit_stream',
    'delete_stream',
    'manage_inventory',
    'view_inventory',
    'edit_inventory',
    'delete_inventory',
    'perform_inventory_check',
    'view_reports',
    'export_data'
  ],
  manager: [
    'view',
    'edit',
    'view_costs',
    'view_all_streams',
    'add_stream',
    'edit_stream',
    'view_inventory',
    'edit_inventory',
    'perform_inventory_check',
    'view_reports'
  ],
  streamer: [
    'view_own_streams',
    'add_stream',
    'view_inventory'
  ]
};

// Demo accounts with fixed IDs to ensure consistency
const demoAccounts: User[] = [
  {
    id: 'admin-demo',
    email: 'admin@example.com',
    password: 'admin123',
    role: 'admin',
    name: 'Admin User',
    emailVerified: true,
    permissions: defaultPermissions.admin
  },
  {
    id: 'manager-demo',
    email: 'manager@example.com',
    password: 'manager123',
    role: 'manager',
    name: 'Manager User',
    emailVerified: true,
    permissions: defaultPermissions.manager
  },
  {
    id: 'streamer1-demo',
    email: 'streamer1@example.com',
    password: 'streamer123',
    role: 'streamer',
    name: 'John Streamer',
    emailVerified: true,
    streamerId: 'str-1',
    permissions: defaultPermissions.streamer
  },
  {
    id: 'streamer2-demo',
    email: 'streamer2@example.com',
    password: 'streamer123',
    role: 'streamer',
    name: 'Jane Streamer',
    emailVerified: true,
    streamerId: 'str-2',
    permissions: defaultPermissions.streamer
  }
];

const loadUsers = (): User[] => {
  try {
    const savedUsers = storage.loadUsers(demoAccounts);
    // Ensure demo accounts are always present
    const demoEmails = demoAccounts.map(user => user.email);
    const nonDemoUsers = savedUsers.filter(user => !demoEmails.includes(user.email));
    const finalUsers = [...demoAccounts, ...nonDemoUsers];
    storage.saveUsers(finalUsers);
    return finalUsers;
  } catch (error) {
    console.error('Error loading users:', error);
    return demoAccounts;
  }
};

export let users = loadUsers();

export const updateUsers = (newUsers: User[]) => {
  // Ensure demo accounts cannot be modified
  const demoEmails = demoAccounts.map(user => user.email);
  const nonDemoUsers = newUsers.filter(user => !demoEmails.includes(user.email));
  users = [...demoAccounts, ...nonDemoUsers];
  storage.saveUsers(users);
};

export function hasPermission(user: User | null, permission: Permission): boolean {
  if (!user || !user.emailVerified) return false;
  return user.permissions.includes(permission);
}

export function canViewStream(user: User | null, streamerId: string): boolean {
  if (!user) return false;
  if (hasPermission(user, 'view_all_streams')) return true;
  return user.role === 'streamer' && user.streamerId === streamerId && hasPermission(user, 'view_own_streams');
}

// Email verification tokens storage
const verificationTokens = new Map<string, { email: string; expires: number }>();

export function generateVerificationToken(email: string): string {
  const token = generateId('verify-');
  verificationTokens.set(token, {
    email,
    expires: Date.now() + 24 * 60 * 60 * 1000 // 24 hours
  });
  return token;
}

export function verifyEmailToken(token: string): string | null {
  const verification = verificationTokens.get(token);
  if (!verification) return null;
  
  if (verification.expires < Date.now()) {
    verificationTokens.delete(token);
    return null;
  }

  verificationTokens.delete(token);
  return verification.email;
}