import { InventoryItem, Stream } from '@/lib/types';

export const initialInventory: InventoryItem[] = [
  { id: 1, name: "2021 Topps Chrome", quantity: 50, cost: 199.99, category: "Baseball" },
  { id: 2, name: "2022 Panini Prizm", quantity: 30, cost: 299.99, category: "Basketball" },
  { id: 3, name: "2023 Bowman Draft", quantity: 40, cost: 149.99, category: "Baseball" },
  { id: 4, name: "2023 Upper Deck Series 1", quantity: 25, cost: 89.99, category: "Hockey" },
  { id: 5, name: "2022 Panini Contenders", quantity: 35, cost: 179.99, category: "Football" },
];

export const initialCategories = [
  "Baseball",
  "Basketball",
  "Football",
  "Hockey",
  "Other",
  "Uncategorized"
];

export const initialStreams: Stream[] = [
  {
    id: 1,
    date: "2023-11-01",
    startTime: "19:00",
    endTime: "21:30",
    streamer: "John Doe",
    sorter: "Jane Smith",
    soldItems: [
      { id: 1, name: "2021 Topps Chrome", quantity: 50, cost: 199.99, category: "Baseball", quantitySold: 5 },
      { id: 2, name: "2022 Panini Prizm", quantity: 30, cost: 299.99, category: "Basketball", quantitySold: 3 },
    ]
  },
  {
    id: 2,
    date: "2023-11-02",
    startTime: "18:00",
    endTime: "20:00",
    streamer: "Mike Johnson",
    sorter: "Sarah Williams",
    soldItems: [
      { id: 3, name: "2023 Bowman Draft", quantity: 40, cost: 149.99, category: "Baseball", quantitySold: 2 },
      { id: 4, name: "2023 Upper Deck Series 1", quantity: 25, cost: 89.99, category: "Hockey", quantitySold: 1 },
    ]
  },
];