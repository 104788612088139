import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { nanoid } from 'nanoid';
import { StreamItem, InventoryChange, InventoryChangeType } from './types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateId(prefix: string = ''): string {
  return `${prefix}${nanoid()}`;
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}

export function parseCSVValue(value: string): string {
  return value.trim().replace(/^["']|["']$/g, '');
}

export function calculateStreamDuration(startTime: string, endTime: string): string {
  const start = new Date(`1970-01-01T${startTime}`);
  const end = new Date(`1970-01-01T${endTime}`);
  
  // If end time is before start time, add 24 hours to end time
  if (end < start) {
    end.setDate(end.getDate() + 1);
  }
  
  const durationMs = end.getTime() - start.getTime();
  const hours = durationMs / (1000 * 60 * 60);
  return hours.toFixed(2);
}

export function calculateTotal(items: { cost: number; quantity: number; }[]): number {
  return items.reduce((total, item) => total + (item.cost * item.quantity), 0);
}

export function formatTime(time: string): string {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minutes} ${ampm}`;
}

export function calculateTotalCost(items: StreamItem[]): number {
  return items.reduce((sum, item) => sum + (item.cost * item.quantitySold), 0);
}

export function calculateGrossProfit(sales: number, cost: number): number {
  return sales - cost;
}

export function createInventoryChange(
  type: InventoryChangeType,
  items: { name: string; quantityChange: number }[],
  userName: string,
  details?: string
): InventoryChange {
  return {
    id: generateId('change-'),
    date: new Date().toISOString(),
    type,
    items,
    user: userName,
    details
  };
}

export function formatDate(date: string): string {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
}