import { User, InventoryItem, Stream, InventoryCheck, InventoryChange } from './types';

const STORAGE_KEYS = {
  INVENTORY: 'cardquant_inventory',
  STREAMS: 'cardquant_streams',
  INVENTORY_CHECKS: 'cardquant_checks',
  INVENTORY_CHANGES: 'cardquant_changes',
  CATEGORIES: 'cardquant_categories',
  USERS: 'cardquant_users',
  CURRENT_USER: 'cardquant_current_user'
} as const;

// Synchronize with localStorage
const syncWithLocalStorage = <T>(key: string, data: T): T => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return data;
  } catch (error) {
    console.error(`Error syncing data for key ${key}:`, error);
    return data;
  }
};

// Load data from localStorage with fallback
const loadFromLocalStorage = <T>(key: string, defaultValue: T): T => {
  try {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : defaultValue;
  } catch (error) {
    console.error(`Error loading data for key ${key}:`, error);
    return defaultValue;
  }
};

// Create a singleton instance to manage state
class StorageManager {
  private static instance: StorageManager;
  private cache: Map<string, any>;

  private constructor() {
    this.cache = new Map();
    this.initializeCache();
  }

  static getInstance(): StorageManager {
    if (!StorageManager.instance) {
      StorageManager.instance = new StorageManager();
    }
    return StorageManager.instance;
  }

  private initializeCache() {
    Object.values(STORAGE_KEYS).forEach(key => {
      const data = loadFromLocalStorage(key, null);
      if (data) {
        this.cache.set(key, data);
      }
    });
  }

  private getFromCache<T>(key: string, defaultValue: T): T {
    if (!this.cache.has(key)) {
      const data = loadFromLocalStorage(key, defaultValue);
      this.cache.set(key, data);
    }
    return this.cache.get(key);
  }

  private setInCache<T>(key: string, data: T): T {
    this.cache.set(key, data);
    return syncWithLocalStorage(key, data);
  }

  saveInventory(inventory: InventoryItem[]): void {
    this.setInCache(STORAGE_KEYS.INVENTORY, inventory);
  }

  loadInventory(defaultInventory: InventoryItem[]): InventoryItem[] {
    return this.getFromCache(STORAGE_KEYS.INVENTORY, defaultInventory);
  }

  saveStreams(streams: Stream[]): void {
    this.setInCache(STORAGE_KEYS.STREAMS, streams);
  }

  loadStreams(defaultStreams: Stream[]): Stream[] {
    return this.getFromCache(STORAGE_KEYS.STREAMS, defaultStreams);
  }

  saveInventoryChecks(checks: InventoryCheck[]): void {
    this.setInCache(STORAGE_KEYS.INVENTORY_CHECKS, checks);
  }

  loadInventoryChecks(defaultChecks: InventoryCheck[]): InventoryCheck[] {
    return this.getFromCache(STORAGE_KEYS.INVENTORY_CHECKS, defaultChecks);
  }

  saveInventoryChanges(changes: InventoryChange[]): void {
    this.setInCache(STORAGE_KEYS.INVENTORY_CHANGES, changes);
  }

  loadInventoryChanges(defaultChanges: InventoryChange[]): InventoryChange[] {
    return this.getFromCache(STORAGE_KEYS.INVENTORY_CHANGES, defaultChanges);
  }

  saveCategories(categories: string[]): void {
    this.setInCache(STORAGE_KEYS.CATEGORIES, categories);
  }

  loadCategories(defaultCategories: string[]): string[] {
    return this.getFromCache(STORAGE_KEYS.CATEGORIES, defaultCategories);
  }

  saveUsers(users: User[]): void {
    this.setInCache(STORAGE_KEYS.USERS, users);
  }

  loadUsers(defaultUsers: User[]): User[] {
    return this.getFromCache(STORAGE_KEYS.USERS, defaultUsers);
  }

  saveCurrentUser(user: User | null): void {
    this.setInCache(STORAGE_KEYS.CURRENT_USER, user);
  }

  loadCurrentUser(): User | null {
    return this.getFromCache<User | null>(STORAGE_KEYS.CURRENT_USER, null);
  }

  clearAll(): void {
    this.cache.clear();
    Object.values(STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
  }
}

// Export a singleton instance
export const storage = StorageManager.getInstance();